import axios from "axios";
import { CountryEnum } from "./CountryHelper";
import { LanguageEnum } from "./LanguageHelper";

// var AF_TestFunct = Utility.getValue([AzureFunctions.AF_TestFunct_PROD, AzureFunctions.AF_TestFunct_DEV]);
// var AF_TestFunct_Code = Utility.getValue([AzureFunctions.AF_TestFunct_Code_PROD, AzureFunctions.AF_TestFunct_Code_DEV]);

const axios_instance = axios.create({
  baseURL: process.env.REACT_APP_AF_url,
  headers: {
    "x-functions-key": process.env.REACT_APP_AF_clientKey,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export class SiteHelper {
  //#region B2E User
  // public static async funttest(paramtest: string): Promise<any> {

  //     return '';

  // }

  public static async GetRecaptchaValidationResponse(
    humanKey: string
  ): Promise<string> {
    const body: string = JSON.stringify({
      humanKey: humanKey,
    });

    const result = await axios_instance
      .post("/api/RecaptchaValidation", body)
      .then((res) => {
        console.log(res.data);
        if (res.data.result.result) {
          return "";
        } else {
          return res.data.result.errorMessage;
        }
      })
      .catch((err) => {
        console.error(err);

        if (err.response && err.response.data) {
          return err.response.data.message;
        } else {
          return err.message;
        }
      });

    return SiteHelper.ManageErrorMessage(result);
  }

  public static async CheckUser(badgeID: string, CF: string, country: CountryEnum, lang: LanguageEnum): Promise<any> {
    var resultMsg = "";
    const body: string = JSON.stringify({
      badgeID: badgeID,
      codF: CF,
      country: country,
      lang: lang
    });

    const response = await axios_instance
      .post("/api/CheckUser", body)
      .then((res) => {
        console.log(res.data);
        resultMsg = "";
        var response = res.data;

        if (response != null) {
          if (response.success) {
            if (!response.result.userValid) {
              resultMsg = response.result.errorMessage;
            }
          } else {
            return response.errorMessage;
          }
        }

        return resultMsg;
        // return res.data;
      })
      .catch((err) => {
        console.error(err);
        if (err.response && err.response.data) {
          return err.response.data.message;
        } else {
          return err.message;
        }
      });

    return SiteHelper.ManageErrorMessage(response);
  }

  public static async CreateRequest(
    badgeID: string,
    CF: string,
    email: string,
    country: CountryEnum,
    lang: LanguageEnum
  ): Promise<boolean> {
    const status = "test";
    const body: string = JSON.stringify({
      badgeID: badgeID,
      codF: CF,
      email: email,
      country: country,
      language: lang
    });

    const response = await axios_instance
      .post("/api/CreateRequest", body)
      .then((res) => {
        console.log(res.data);

        if (res != null && res.data != null) {
          return res.data.result;
        } else {
          return false;
        }
        //return res.data.result;
      })
      .catch((err) => {
        console.error(err);

        return false;
      });

    return response;
  }

  public static ManageErrorMessage(errorMessage: string): string {
    var result = errorMessage as string;
    if (errorMessage.indexOf("|") > 0) {
      var array = errorMessage.split("|");
      var errorCode = array[0];
      var result = array[1];
      SiteHelper.TranslateErrorMessage(errorCode, result);
    }

    return result;
  }

  public static TranslateErrorMessage(
    errorCode: string,
    errorMessage: string
  ): string {
    var result = "";
    var defaultlanguage = "it";
    var browserLanguages = SiteHelper.getBrowserLocales({
      languageCodeOnly: true,
    });
    //   console.log('Lang = ' + browserLanguages);
    if (browserLanguages) {
      defaultlanguage = browserLanguages[0];
      // console.log(defaultlanguage);
    }

    switch (errorCode) {
      default:
        result = errorMessage;
        break;
    }

    return result;
  }

  public static getBrowserLocales(options = {}) {
    const defaultOptions = {
      languageCodeOnly: false,
    };
    const opt = {
      ...defaultOptions,
      ...options,
    };
    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;
    if (!browserLocales) {
      return undefined;
    }
    return browserLocales.map((locale) => {
      const trimmedLocale = locale.trim();
      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    });
  }
}
