import React, { useEffect } from 'react';
import logo from './Header_Logo.png';

import header from './CNH_Header.png';
import footer from './CNH_Footer.png';
import background from './CNH_TransparentBackground.png';
import phone from './Phone.svg';
import link from './Chat.svg';
import accordion_icon from './Accordion.png';
import './App.css';
import {
  fluentAccordion,
  fluentAccordionItem,
  provideFluentDesignSystem
} from "@fluentui/web-components";
import { provideReactWrapper } from '@microsoft/fast-react-wrapper';
import { SupportLinks } from './SupportLinks';
import { useTranslation, Trans } from 'react-i18next';
import i18n from "./i18n";
import { PageProps } from "./interfaces/ComponentProps";


const { wrap } = provideReactWrapper(React, provideFluentDesignSystem());
export const FluentAccordionItem = wrap(fluentAccordionItem());
export const FluentAccordion = wrap(fluentAccordion());

export const HelpPage = ({ lang, country }: PageProps) => {
  const { t } = useTranslation(['helpPage']);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    i18n.changeLanguage(lang);
  }, [lang])

  return (
    <div className='Container-Main'>
      <div style={{ cursor: 'pointer' }} onClick={() => window.location.replace(`${window.location.origin}`)}>
        <img src={header} className="App-logo" alt="logo" />
      </div>
      <div className="App">
        <header className="App-header">
          <div className="App_body">
            <Trans t={t} i18nKey="header" components={{ em: <em />, p: <p />, strong: <strong /> }} />
            <FluentAccordion>
              <FluentAccordionItem className={"box-shadow"}>
                <span slot="heading">
                  <img src={accordion_icon} className="App-img-accordion" alt="accordion" />
                  <em className={"accordion-em"}>
                    <Trans t={t} i18nKey="accordion1.header" components={{ em: <em />, p: <p />, strong: <strong /> }} />
                  </em>
                </span>
                <div className={"panel2"}>
                  <Trans t={t} i18nKey="accordion1.content" components={{ em: <em />, p: <p />, strong: <strong /> }} />
                </div>
              </FluentAccordionItem>
              <FluentAccordionItem className={"box-shadow"}>
                <span slot="heading"><img src={accordion_icon} className="App-img-accordion" alt="accordion" />
                  <em className={"accordion-em"}>
                    <Trans t={t} i18nKey="accordion2.header" components={{ em: <em />, p: <p />, strong: <strong /> }} />
                  </em>
                </span>
                <div className={"panel2"}>
                  <Trans t={t} i18nKey="accordion2.content" components={{ em: <em />, p: <p />, strong: <strong /> }} />
                </div>
              </FluentAccordionItem>
              <FluentAccordionItem className={"box-shadow"}>
                <span slot="heading"><img src={accordion_icon} className="App-img-accordion" alt="accordion" />
                  <em className={"accordion-em"}>
                    <Trans t={t} i18nKey="accordion3.header" components={{ em: <em />, p: <p />, strong: <strong /> }} />
                  </em>
                </span>
                <div className={"panel2"}>
                  <Trans t={t} i18nKey="accordion3.content" components={{ em: <em />, p: <p />, strong: <strong /> }} />
                </div>
              </FluentAccordionItem>
            </FluentAccordion>
            {/* <SupportLinks/> */}
          </div>
        </header>
      </div>
      <footer>
        <img src={footer} className="App-footer" alt="logo" />
      </footer>
    </div>
  );
};
