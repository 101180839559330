
import React from 'react';
import { getTeamsInstallManualByCountryCode } from './helpers/CountryHelper';
import { CountryPageProps } from './interfaces/ComponentProps';

const AppInstall = ({country} : CountryPageProps) => {
    const fileUrl = getTeamsInstallManualByCountryCode(country);
    
    React.useEffect(() => {
      window.location.replace(process.env.REACT_APP_SiteUrl+fileUrl); 
    });
  
    return (<></>);
         
 }

 
export default AppInstall;