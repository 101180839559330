import { useEffect } from "react";
import { StepSubmittedProps } from "./interfaces/ComponentProps";
import { Trans, useTranslation } from "react-i18next";
import i18n from "./i18n";



export function StepSubmitted({ lang }: StepSubmittedProps) {

  const { t } = useTranslation(['onBoard']);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    i18n.changeLanguage(lang);
  }, [lang])

  return (
    <div>
      <p className="submitted">
        <strong>
          <Trans t={t} i18nKey="labelRequestSubmitted_firstpart" components={{ em: <em />, p: <p />, strong: <strong />, br: <br /> }} />
        </strong>
      </p>
      <p className="submitted">
        <strong>
          <Trans t={t} i18nKey="labelRequestSubmitted_secodpart" components={{ em: <em />, p: <p />, strong: <strong />, br: <br /> }} />
        </strong>
      </p>
    </div>
  )
}
