import React from 'react';
import { CountryPageProps } from './interfaces/ComponentProps';

function LegacyRedirect({ country }: CountryPageProps) {
  React.useEffect(() => {
    const currentPath = process.env.REACT_APP_SiteUrl ?? "";

    window.location.replace(`${currentPath}/${country}/onboard/${country}`);
  }, [country]);

  return null;
}

export default LegacyRedirect;
