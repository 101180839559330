import React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import { HelpPage } from './HelpPage';
import HomePage from './HomePage';
import NoMatch from './NoMatch';
import { OnBoard } from "./Onboard"
import HelpRedirect from './HelpRedirect';
import OnBoardRedirect from './OnBoardRedirect';
import AppInstall from './AppInstall';
import { LanguageEnum, isLanguageValid } from './helpers/LanguageHelper';
import { CountryConfig } from './interfaces/Config';
import { CountryEnum, isCountryValid } from './helpers/CountryHelper';
import LegacyRedirect from './LegacyRedirect';
import CountryGrid from './CountryGrid';


function App() {
  const countries: CountryConfig[] = JSON.parse(process.env.REACT_APP_COUNTRIES || '[]');

  return (
    <div>
      <Routes>
        <Route key="home" path="/" element={<CountryGrid countries={countries} />} />
        {countries.map(c => {
          if (!isCountryValid(c.Country) || !isLanguageValid(c.AvailableLangs)) {
            console.error("Invalid Language");
            return <></>;
          }
          else return (<>
            <Route key="onboardLegacy" path={`onboard/${c.Country}`} element={<LegacyRedirect country={c.Country} />} />
            <Route key={c.Country} path={`/${c.Country}`}>
              <Route index element={<HomePage country={c.Country} />} />
              <Route path="help" element={<HelpRedirect country={c.Country as CountryEnum} />} />
              <Route path="onboard" element={<OnBoardRedirect country={c.Country as CountryEnum} />} />
                  <Route path={`appinstall`} element={<AppInstall country={c.Country as CountryEnum} />} />
              {c.AvailableLangs.map(lang => (
                <React.Fragment key={`${c.Country}-${lang}`}>
                  <Route path={`help/${lang}`} element={<HelpPage country={c.Country as CountryEnum} lang={lang as LanguageEnum} />} />
                  <Route path={`onboard/${lang}`} element={<OnBoard country={c.Country as CountryEnum} lang={lang as LanguageEnum} />} />
                </React.Fragment>
              ))}
              {/* Using path="*"" means "match anything", so this route acts like a catch-all for URLs that we don't have explicit routes for. */}
              <Route path="*" element={<NoMatch />} />
            </Route>
            </>)
        })}
      </Routes>
    </div>
  );
}



export default App;
